import { BUS_EVENTS } from '../../../shared/constants/busEvents';
import { kratosApi } from '../../api/kratos';
import store from '../../store';
import types from '../../store/mutation-types';

export const CHECKS_SESSION_HEALTH_INTERVAL = 5 * 60 * 1000;

export const checkSessionHealth = async () => {
  kratosApi
    .toSession()
    .then(({ data: session }) => {
      store.commit(types.SET_SESSION, session);
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log('[KRATOS SESSION] - Cant fetch session', e.response);
      if (e.response?.status === 401 || e.response?.status === 403) {
        bus.$emit(BUS_EVENTS.SESSION_EXPIRED);
      }
    });
};
