<template>
  <span class="c-checkmark-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      class="c-checkmark-icon__regular"
    >
      <path
        d="M4 9.17647L6.66667 12L12 4"
        stroke="#5956E3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      class="c-checkmark-icon__hovered"
      :class="{ 'c-checkmark-icon__hovered--visible': isHovered }"
    >
      <g filter="url(#filter0_ddiii_4384_3966)">
        <path
          d="M5 9.17647L7.66667 12L13 4"
          stroke="#5956E3"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddiii_4384_3966"
          x="0.25"
          y="0.24992"
          width="17.5001"
          height="17.5001"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.427451 0 0 0 0 0.615686 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4384_3966"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.427451 0 0 0 0 0.615686 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4384_3966"
            result="effect2_dropShadow_4384_3966"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4384_3966"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.7" />
          <feGaussianBlur stdDeviation="0.35" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.521569 0 0 0 0 0.45098 0 0 0 0 0.905882 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_4384_3966"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.737255 0 0 0 0 0.698039 0 0 0 0 0.94902 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_4384_3966"
            result="effect4_innerShadow_4384_3966"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.1" />
          <feGaussianBlur stdDeviation="0.2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.807843 0 0 0 0 0.780392 0 0 0 0 0.960784 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_4384_3966"
            result="effect5_innerShadow_4384_3966"
          />
        </filter>
      </defs>
    </svg> -->
  </span>
</template>
<script>
export default {
  props: {
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.c-checkmark-icon {
  &__regular {
    transition: opacity 0.2s ease;
    position: relative;
    right: 0;
    bottom: -2px;

    // &--hidden {
    //   opacity: 0;
    // }
  }

  // &__hovered {
  //   transition: opacity 0.2s ease;
  //   opacity: 0;
  //   right: 0px;
  //   bottom: 0px;

  //   &--visible {
  //     opacity: 1;
  //   }
  // }
}
</style>
