<template>
  <div class="agent-table-container ph-no-capture">
    <ps-table>
      <ps-table-head>
        <ps-table-row>
          <ps-table-cell v-for="item in columns" :key="item.title" variant="th">
            {{ item.title }}
          </ps-table-cell>
        </ps-table-row>
      </ps-table-head>
      <ps-table-body>
        <ps-table-row v-for="item in tableData" :key="item.email">
          <ps-table-cell>
            <div class="flex gap-2">
              <thumbnail
                :src="item.thumbnail"
                :username="item.agent"
                :status="item.status || 'offline'"
                should-show-status-always
                size="32px"
              />
              <div>
                <ps-text-subtitle>{{ item.agent }}</ps-text-subtitle>
                <ps-text-body>{{ item.email }}</ps-text-body>
              </div>
            </div>
          </ps-table-cell>
          <ps-table-cell>
            <ps-text-body>{{ item.open }}</ps-text-body>
          </ps-table-cell>
          <ps-table-cell>
            <ps-text-body variant="secondary">
              {{ item.unattended }}
            </ps-text-body>
          </ps-table-cell>
        </ps-table-row>
      </ps-table-body>
    </ps-table>
    <div v-if="isLoading" class="agents-loader">
      <spinner />
      <span>{{
        $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
      }}</span>
    </div>
    <empty-state
      v-else-if="!isLoading && !agentMetrics.length"
      :title="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_AGENTS')"
    />
    <div
      v-if="agentMetrics.length > 0"
      class="flex gap-3 align-middle justify-end mt-4"
    >
      <span class="text-sm text-pype-grey-400">
        {{ this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.PAGINATION.TOTAL') }}:
        {{ agents.length }}
      </span>
      <ve-pagination
        :total="agents.length"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        :layout="['prev', 'pager', 'next']"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
  </div>
</template>

<script>
import { VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  name: 'AgentTable',
  components: {
    EmptyState,
    Spinner,
    VePagination,
    Thumbnail,
  },
  mixins: [rtlMixin],
  props: {
    agents: {
      type: Array,
      default: () => [],
    },
    agentMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    tableData() {
      const reduced = this.agentMetrics.reduce((filtered, agent) => {
        const agentInformation = this.getAgentInformation(agent.id);
        if (agentInformation) {
          filtered.push({
            agent: agentInformation?.name,
            email: agentInformation?.email,
            thumbnail: agentInformation?.thumbnail,
            open: agent?.metric?.open || 0,
            unattended: agent?.metric?.unattended || 0,
            status: agentInformation?.availability_status,
          });
        }
        return filtered;
      }, []);
      return reduced;
    },
    columns() {
      return [
        {
          field: 'agent',
          key: 'agent',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'
          ),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 25,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.thumbnail}
                size="32px"
                username={row.agent}
                status={row.status}
              />
              <div class="user-block">
                <h6 class="title overflow-hidden whitespace-nowrap text-ellipsis">
                  {row.agent}
                </h6>
                <span class="sub-title">{row.email}</span>
              </div>
            </div>
          ),
        },
        {
          field: 'open',
          key: 'open',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: 10,
        },
        {
          field: 'unattended',
          key: 'unattended',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.UNATTENDED'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: 10,
        },
      ];
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    getAgentInformation(id) {
      return this.agents.find(agent => agent.id === Number(id));
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-table-container {
  @apply flex flex-col flex-1;

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination .ve-pagination-li.ve-pagination-li-active {
    border-color: var(--pype-purple-300);
  }

  &::v-deep .ve-pagination {
    @apply bg-transparent dark:bg-transparent;
  }

  &::v-deep .ve-pagination-select {
    @apply hidden;
  }

  .row-user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col min-w-0 my-0 mx-2;

      .title {
        @apply text-sm m-0 leading-[1.2] text-slate-800 dark:text-slate-100;
      }
      .sub-title {
        @apply text-xs text-slate-600 dark:text-slate-200;
      }
    }
  }
}

.agents-loader {
  @apply items-center flex text-base justify-center p-8;
}
</style>
