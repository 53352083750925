import { Configuration, FrontendApi } from '@ory/client';

export const kratosApi = new FrontendApi(
  new Configuration({
    basePath: window.contactCenterConfig.oryFrontendEndpoint,
    baseOptions: {
      withCredentials: true,
    },
  })
);
