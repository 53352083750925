export const BUS_EVENTS = {
  SET_TWEET_REPLY: 'SET_TWEET_REPLY',
  SHOW_ALERT: 'SHOW_ALERT',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
  FOCUS_CUSTOM_ATTRIBUTE: 'FOCUS_CUSTOM_ATTRIBUTE',
  SCROLL_TO_MESSAGE: 'SCROLL_TO_MESSAGE',
  MESSAGE_SENT: 'MESSAGE_SENT',
  FETCH_LABEL_SUGGESTIONS: 'FETCH_LABEL_SUGGESTIONS',
  TOGGLE_SIDEMENU: 'TOGGLE_SIDEMENU',
  ON_MESSAGE_LIST_SCROLL: 'ON_MESSAGE_LIST_SCROLL',
  WEBSOCKET_DISCONNECT: 'WEBSOCKET_DISCONNECT',
  WEBSOCKET_RECONNECT: 'WEBSOCKET_RECONNECT',
  SHOW_TOAST: 'newToastMessage',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  REFETCH_CONVERSATIONS: 'REFETCH_CONVERSATIONS',
  FOCUS_WIDGET_INPUT: 'FOCUS_WIDGET_INPUT',
};
