<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="options-menu dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <div class="c-menu__header">
        <thumbnail
          :src="currentUser.avatar_url"
          :username="currentUser.name"
          :status="statusOfAgent"
          should-show-status-always
          size="32px"
          class="ph-no-capture"
        />
        <div class="c-menu__user-info">
          <span class="c-menu__user-name ph-no-capture">{{
            currentUser.name
          }}</span>
          <span class="c-menu__user-email ph-no-capture">{{
            currentUser.email
          }}</span>
        </div>
      </div>
      <woot-dropdown-divider />
      <availability-status
        @change-availability-status="$emit('change-availability-status')"
      />
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="showChangeAccountOption">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            class="c-menu__link"
            size="small"
            @click="$emit('toggle-accounts')"
          >
            {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="globalConfig.chatwootInboxToken">
          <woot-button
            variant="clear"
            class="c-menu__link"
            color-scheme="secondary"
            size="small"
            @click="$emit('show-support-chat-window')"
          >
            {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item class="show-for-medium">
          <woot-button
            variant="clear"
            class="c-menu__link"
            color-scheme="secondary"
            size="small"
            @click="handleKeyboardHelpClick"
          >
            {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <router-link
            v-slot="{ href, isActive, navigate }"
            :to="`/app/accounts/${accountId}/profile/settings`"
            custom
          >
            <a
              :href="href"
              class="button small clear secondary c-menu__link"
              :class="{ 'is-active': isActive }"
              @click="e => handleProfileSettingClick(e, navigate)"
            >
              <span class="button__content">
                {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
              </span>
            </a>
          </router-link>
        </woot-dropdown-item>
        <!-- <woot-dropdown-item>
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            @click="openAppearanceOptions"
          >
            {{ $t('SIDEBAR_ITEMS.APPEARANCE') }}
          </woot-button>
        </woot-dropdown-item> -->
        <woot-dropdown-item v-if="currentUser.type === 'SuperAdmin'">
          <a
            href="/super_admin"
            class="button small clear secondary c-menu__link"
            target="_blank"
            rel="noopener nofollow noreferrer"
            @click="$emit('close')"
          >
            <span class="button__content">
              {{ $t('SIDEBAR_ITEMS.SUPER_ADMIN_CONSOLE') }}
            </span>
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            class="c-menu__link"
            color-scheme="secondary"
            size="small"
            @click="logout"
          >
            {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
import Thumbnail from '../../widgets/Thumbnail';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
    Thumbnail,
    WootDropdownDivider,
  },
  mixins: [clickaway],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
    openAppearanceOptions() {
      const ninja = document.querySelector('ninja-keys');
      ninja.open({ parent: 'appearance_settings' });
    },
  },
};
</script>
<style lang="scss" scoped>
.options-menu.dropdown-pane {
  right: var(--space-slab);
  top: var(--space-larger);
  min-width: 280px;
  z-index: var(--z-index-low);
  padding: 24px;
}

.c-menu__header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: var(--space-smaller);
}

.c-menu__user-info {
  display: flex;
  flex-direction: column;
}

.c-menu__user-name {
  color: black;
  font-size: var(--font-size-small);
}

.c-menu__user-email {
  color: var(--pype-grey-300);
  font-size: var(--font-size-mini);
}

.c-menu__link.button.secondary {
  padding: var(--space-smaller) var(--space-small);
  height: 40px;
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--gray-500);

  &:hover {
    background-color: var(--hover-light-25);
  }
}
</style>
