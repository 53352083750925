var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ps-nav',{ref:"nav",staticClass:"c-app-bar"},[_c('router-link',{attrs:{"to":_vm.primaryMenuItems[0].toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('logo-component',{attrs:{"type":"desktop","href":href},on:{"click":navigate}},[_vm._v("\n      Contact Center\n    ")])]}}])}),_vm._v(" "),_c('logo-component',{on:{"click":_vm.toggleMobileNav}}),_vm._v(" "),_c('div',{staticClass:"c-app-bar__nav-menu"},[_c('ps-nav-links',_vm._l((_vm.primaryMenuItems),function(item){return _c('router-link',{key:item.toState,attrs:{"to":item.toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('ps-nav-link',{attrs:{"href":href,"active":isActive},on:{"click":navigate}},[_vm._v("\n          "+_vm._s(_vm.$t(("SIDEBAR." + (item.label))))+"\n        ")])]}}],null,true)})}),1)],1),_vm._v(" "),_c('div',{staticClass:"c-app-bar__mobile-drawer",class:{ 'c-app-bar__mobile-drawer--open': _vm.isMobileMenuVisible }},[_c('div',{staticClass:"c-app-bar__mask",on:{"click":_vm.toggleMobileNav}}),_vm._v(" "),_c('div',{staticClass:"c-app-bar__content"},[_c('div',[_c('div',{staticClass:"c-app-bar__mobile-drawer-head"},[_c('router-link',{attrs:{"to":_vm.primaryMenuItems[0].toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('logo-component',{attrs:{"type":"drawer","href":href},on:{"click":navigate}},[_vm._v("\n              Contact Center\n            ")])]}}])}),_vm._v(" "),_c('fluent-icon',{staticClass:"c-app-bar__mobile-drawer-icon",attrs:{"icon":"add"},nativeOn:{"click":function($event){return _vm.toggleMobileNav($event)}}})],1),_vm._v(" "),_c('div',{staticClass:"c-app-bar__mobile-drawer-links row"},_vm._l((_vm.primaryMenuItems),function(item){return _c('router-link',{key:item.toState,attrs:{"to":item.toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"c-app-bar__nav-link columns small-12",class:{ 'c-app-bar__nav-link--active': isActive },attrs:{"href":href},on:{"click":navigate}},[_vm._v("\n              "+_vm._s(_vm.toUpper(item.label))+"\n            ")])]}}],null,true)})}),1)]),_vm._v(" "),_c('div',{staticClass:"c-app-bar__mobile-drawer-footer"},[_c('options-menu-mobile',{on:{"toggle-accounts":_vm.toggleAccountModal,"show-support-chat-window":_vm.toggleSupportChatWindow,"key-shortcut-modal":_vm.toggleKeyShortcutModal,"close":_vm.toggleMobileNav}})],1)])]),_vm._v(" "),_c('app-bar-controls',{attrs:{"account-id":_vm.accountId},on:{"open-notification-panel":_vm.openNotificationPanel,"toggle-accounts":_vm.toggleAccountModal,"key-shortcut-modal":_vm.toggleKeyShortcutModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }