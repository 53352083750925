import { frontendURL } from '../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('error'),
      name: 'error',
      component: () => import('./Error'),
    },
  ],
};
