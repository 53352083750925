<template>
  <a
    v-if="type === 'desktop' || type === 'login'"
    :href="href"
    class="c-logo"
    :class="{
      'c-logo--desktop': type === 'desktop',
      'c-logo--login': type === 'login',
    }"
    @click="handleClick"
    @mouseover="onMouseEnter"
    @mouseout="onMouseLeave"
  >
    <ps-onboarding-modal open :env="envNamespace" />
    <svg
      width="65"
      height="65"
      class="c-logo__icon"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10861_621)">
        <path
          d="M40.7376 32.9C40.7376 34.4 39.9376 35.71 38.7376 36.42L36.7676 32.9L38.7376 29.38C39.9376 30.1 40.7376 31.41 40.7376 32.9Z"
          fill="white"
        />
        <path
          d="M38.2374 33.77C38.8874 33.92 39.4474 33.36 39.2874 32.72C39.2174 32.42 38.9674 32.17 38.6674 32.1C38.0174 31.95 37.4574 32.51 37.6174 33.15C37.6874 33.45 37.9374 33.7 38.2374 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M41.9474 33.77C42.5974 33.92 43.1574 33.36 42.9974 32.72C42.9274 32.42 42.6774 32.17 42.3774 32.1C41.7274 31.95 41.1674 32.51 41.3274 33.15C41.3974 33.45 41.6474 33.7 41.9474 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M45.548 33.77C46.198 33.92 46.758 33.36 46.598 32.72C46.528 32.42 46.278 32.17 45.978 32.1C45.328 31.95 44.768 32.51 44.928 33.15C44.998 33.45 45.248 33.7 45.548 33.77Z"
          fill="#6A5CB9"
        />
      </g>
      <path
        d="M44.8692 45.8601C42.6734 47.1187 40.1348 47.8334 37.4249 47.8334C29.1558 47.8334 22.4291 41.1347 22.4291 32.9001C22.4291 24.6654 29.1558 17.9667 37.4249 17.9667C40.1348 17.9667 42.6734 18.6814 44.8692 19.9507L50.1071 10.6387C46.3688 8.51605 42.0415 7.30005 37.4249 7.30005C23.2217 7.30005 11.7178 18.7667 11.7178 32.9001C11.7178 47.0334 23.2217 58.5001 37.4249 58.5001C42.0415 58.5001 46.3795 57.2947 50.1178 55.1721L44.8692 45.8601Z"
        fill="#8573E7"
      />
      <path
        d="M37.3107 37.284C34.8911 37.284 32.9299 35.3214 32.9299 32.9C32.9299 30.4787 34.8911 28.516 37.3107 28.516C38.1314 28.516 38.8988 28.74 39.5597 29.1454L42.1178 24.6014C40.7108 23.7694 39.0694 23.3 37.3107 23.3C32.0133 23.3 27.7178 27.5987 27.7178 32.9C27.7178 38.2014 32.0133 42.5 37.3107 42.5C39.0587 42.5 40.7002 42.0307 42.1071 41.1987L39.5597 36.6547C38.8988 37.0601 38.1314 37.284 37.3107 37.284Z"
        fill="#8573E7"
      />
      <path
        d="M53.3176 32.9941C53.3176 34.3461 52.7731 35.5696 51.886 36.4567C51.5496 36.7932 51.1642 37.0807 50.7359 37.3009C50.7298 37.3132 50.7237 37.3132 50.7115 37.3193C50.6992 37.3254 50.6809 37.3376 50.6625 37.3499C49.1943 38.1819 48.454 39.6929 48.454 41.4976V41.8524L46.7533 39.1729C46.2517 38.3837 45.5788 37.8882 44.6611 37.8882H37.4117C34.7077 37.8882 32.5176 35.6981 32.5176 32.9941C32.5176 31.6421 33.062 30.4186 33.9491 29.5315C34.8362 28.6506 36.0597 28.1 37.4117 28.1H48.4235C51.1275 28.1 53.3176 30.2901 53.3176 32.9941Z"
        fill="#D8D1F7"
      />
      <path
        d="M39.3522 33.9166C39.86 33.9166 40.2699 33.5067 40.2699 32.9989C40.2699 32.4912 39.86 32.0813 39.3522 32.0813C38.8445 32.0813 38.4346 32.4912 38.4346 32.9989C38.4346 33.5067 38.8445 33.9166 39.3522 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M43.2848 33.9166C43.7926 33.9166 44.2025 33.5067 44.2025 32.9989C44.2025 32.4912 43.7926 32.0813 43.2848 32.0813C42.7771 32.0813 42.3672 32.4912 42.3672 32.9989C42.3672 33.5067 42.7771 33.9166 43.2848 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M47.1022 33.9166C47.61 33.9166 48.0199 33.5067 48.0199 32.9989C48.0199 32.4912 47.61 32.0813 47.1022 32.0813C46.5945 32.0813 46.1846 32.4912 46.1846 32.9989C46.1846 33.5067 46.5945 33.9166 47.1022 33.9166Z"
        fill="#6A5CB9"
      />
      <defs>
        <clipPath id="clip0_10861_621">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.517578 0.900024)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="c-logo__icon c-logo__icon--hovered"
      :class="{ 'c-logo__icon--active': isHovered }"
    >
      <g clip-path="url(#clip0_10861_645)">
        <path
          d="M40.7376 32.9C40.7376 34.4 39.9376 35.71 38.7376 36.42L36.7676 32.9L38.7376 29.38C39.9376 30.1 40.7376 31.41 40.7376 32.9Z"
          fill="white"
        />
        <path
          d="M38.2374 33.77C38.8874 33.92 39.4474 33.36 39.2874 32.72C39.2174 32.42 38.9674 32.17 38.6674 32.1C38.0174 31.95 37.4574 32.51 37.6174 33.15C37.6874 33.45 37.9374 33.7 38.2374 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M41.9474 33.77C42.5974 33.92 43.1574 33.36 42.9974 32.72C42.9274 32.42 42.6774 32.17 42.3774 32.1C41.7274 31.95 41.1674 32.51 41.3274 33.15C41.3974 33.45 41.6474 33.7 41.9474 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M45.548 33.77C46.198 33.92 46.758 33.36 46.598 32.72C46.528 32.42 46.278 32.17 45.978 32.1C45.328 31.95 44.768 32.51 44.928 33.15C44.998 33.45 45.248 33.7 45.548 33.77Z"
          fill="#6A5CB9"
        />
      </g>
      <path
        d="M44.8683 45.8601C42.6724 47.1187 40.1339 47.8334 37.4239 47.8334C29.1548 47.8334 22.4281 41.1347 22.4281 32.9001C22.4281 24.6654 29.1548 17.9667 37.4239 17.9667C40.1339 17.9667 42.6724 18.6814 44.8683 19.9507L50.1061 10.6387C46.3678 8.51605 42.0405 7.30005 37.4239 7.30005C23.2207 7.30005 11.7168 18.7667 11.7168 32.9001C11.7168 47.0334 23.2207 58.5001 37.4239 58.5001C42.0405 58.5001 46.3786 57.2947 50.1168 55.1721L44.8683 45.8601Z"
        fill="#8573E7"
      />
      <path
        d="M37.3097 37.284C34.8902 37.284 32.9289 35.3214 32.9289 32.9C32.9289 30.4787 34.8902 28.516 37.3097 28.516C38.1304 28.516 38.8979 28.74 39.5587 29.1454L42.1168 24.6014C40.7098 23.7694 39.0684 23.3 37.3097 23.3C32.0123 23.3 27.7168 27.5987 27.7168 32.9C27.7168 38.2014 32.0123 42.5 37.3097 42.5C39.0577 42.5 40.6992 42.0307 42.1061 41.1987L39.5587 36.6547C38.8979 37.06 38.1304 37.284 37.3097 37.284Z"
        fill="#8573E7"
      />
      <g filter="url(#filter0_d_10861_645)">
        <path
          d="M53.3166 32.9942C53.3166 34.3462 52.7721 35.5697 51.8851 36.4568C51.5486 36.7933 51.1632 37.0808 50.735 37.301C50.7288 37.3133 50.7227 37.3133 50.7105 37.3194C50.6982 37.3255 50.6799 37.3377 50.6615 37.35C49.1933 38.182 48.4531 39.693 48.4531 41.4977V41.8526L46.7524 39.173C46.2507 38.3839 45.5778 37.8883 44.6601 37.8883H37.4107C34.7067 37.8883 32.5166 35.6982 32.5166 32.9942C32.5166 31.6422 33.0611 30.4187 33.9481 29.5316C34.8352 28.6507 36.0587 28.1001 37.4107 28.1001H48.4225C51.1265 28.1001 53.3166 30.2902 53.3166 32.9942Z"
          fill="url(#paint0_linear_10861_645)"
        />
      </g>
      <path
        d="M39.3503 33.9166C39.858 33.9166 40.2679 33.5067 40.2679 32.9989C40.2679 32.4912 39.858 32.0813 39.3503 32.0813C38.8425 32.0813 38.4326 32.4912 38.4326 32.9989C38.4326 33.5067 38.8425 33.9166 39.3503 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M43.2829 33.9166C43.7906 33.9166 44.2005 33.5067 44.2005 32.9989C44.2005 32.4912 43.7906 32.0813 43.2829 32.0813C42.7751 32.0813 42.3652 32.4912 42.3652 32.9989C42.3652 33.5067 42.7751 33.9166 43.2829 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M47.1003 33.9166C47.608 33.9166 48.0179 33.5067 48.0179 32.9989C48.0179 32.4912 47.608 32.0813 47.1003 32.0813C46.5925 32.0813 46.1826 32.4912 46.1826 32.9989C46.1826 33.5067 46.5925 33.9166 47.1003 33.9166Z"
        fill="#6A5CB9"
      />
      <defs>
        <filter
          id="filter0_d_10861_645"
          x="28.5166"
          y="28.1001"
          width="28.7998"
          height="21.7524"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.313726 0 0 0 0 0.227451 0 0 0 0 0.772549 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10861_645"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10861_645"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_10861_645"
          x1="42.9166"
          y1="28.1001"
          x2="42.9166"
          y2="41.8526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#AF9EFF" />
        </linearGradient>
        <clipPath id="clip0_10861_645">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.517578 0.900024)"
          />
        </clipPath>
      </defs>
    </svg>
    <span class="c-logo__text"><slot /></span>
  </a>
  <button
    v-else-if="type == 'mobile'"
    :href="href"
    class="c-logo c-logo--mobile"
    @click="handleClick"
  >
    <svg
      width="30"
      height="30"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="c-logo__mobile-icon"
      :class="{ 'c-logo__mobile-icon--invisible': isBurgerVisible }"
    >
      <g clip-path="url(#clip0_10861_621)">
        <path
          d="M40.7376 32.9C40.7376 34.4 39.9376 35.71 38.7376 36.42L36.7676 32.9L38.7376 29.38C39.9376 30.1 40.7376 31.41 40.7376 32.9Z"
          fill="white"
        />
        <path
          d="M38.2374 33.77C38.8874 33.92 39.4474 33.36 39.2874 32.72C39.2174 32.42 38.9674 32.17 38.6674 32.1C38.0174 31.95 37.4574 32.51 37.6174 33.15C37.6874 33.45 37.9374 33.7 38.2374 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M41.9474 33.77C42.5974 33.92 43.1574 33.36 42.9974 32.72C42.9274 32.42 42.6774 32.17 42.3774 32.1C41.7274 31.95 41.1674 32.51 41.3274 33.15C41.3974 33.45 41.6474 33.7 41.9474 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M45.548 33.77C46.198 33.92 46.758 33.36 46.598 32.72C46.528 32.42 46.278 32.17 45.978 32.1C45.328 31.95 44.768 32.51 44.928 33.15C44.998 33.45 45.248 33.7 45.548 33.77Z"
          fill="#6A5CB9"
        />
      </g>
      <path
        d="M44.8692 45.8601C42.6734 47.1187 40.1348 47.8334 37.4249 47.8334C29.1558 47.8334 22.4291 41.1347 22.4291 32.9001C22.4291 24.6654 29.1558 17.9667 37.4249 17.9667C40.1348 17.9667 42.6734 18.6814 44.8692 19.9507L50.1071 10.6387C46.3688 8.51605 42.0415 7.30005 37.4249 7.30005C23.2217 7.30005 11.7178 18.7667 11.7178 32.9001C11.7178 47.0334 23.2217 58.5001 37.4249 58.5001C42.0415 58.5001 46.3795 57.2947 50.1178 55.1721L44.8692 45.8601Z"
        fill="#8573E7"
      />
      <path
        d="M37.3107 37.284C34.8911 37.284 32.9299 35.3214 32.9299 32.9C32.9299 30.4787 34.8911 28.516 37.3107 28.516C38.1314 28.516 38.8988 28.74 39.5597 29.1454L42.1178 24.6014C40.7108 23.7694 39.0694 23.3 37.3107 23.3C32.0133 23.3 27.7178 27.5987 27.7178 32.9C27.7178 38.2014 32.0133 42.5 37.3107 42.5C39.0587 42.5 40.7002 42.0307 42.1071 41.1987L39.5597 36.6547C38.8988 37.0601 38.1314 37.284 37.3107 37.284Z"
        fill="#8573E7"
      />
      <path
        d="M53.3176 32.9941C53.3176 34.3461 52.7731 35.5696 51.886 36.4567C51.5496 36.7932 51.1642 37.0807 50.7359 37.3009C50.7298 37.3132 50.7237 37.3132 50.7115 37.3193C50.6992 37.3254 50.6809 37.3376 50.6625 37.3499C49.1943 38.1819 48.454 39.6929 48.454 41.4976V41.8524L46.7533 39.1729C46.2517 38.3837 45.5788 37.8882 44.6611 37.8882H37.4117C34.7077 37.8882 32.5176 35.6981 32.5176 32.9941C32.5176 31.6421 33.062 30.4186 33.9491 29.5315C34.8362 28.6506 36.0597 28.1 37.4117 28.1H48.4235C51.1275 28.1 53.3176 30.2901 53.3176 32.9941Z"
        fill="#D8D1F7"
      />
      <path
        d="M39.3522 33.9166C39.86 33.9166 40.2699 33.5067 40.2699 32.9989C40.2699 32.4912 39.86 32.0813 39.3522 32.0813C38.8445 32.0813 38.4346 32.4912 38.4346 32.9989C38.4346 33.5067 38.8445 33.9166 39.3522 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M43.2848 33.9166C43.7926 33.9166 44.2025 33.5067 44.2025 32.9989C44.2025 32.4912 43.7926 32.0813 43.2848 32.0813C42.7771 32.0813 42.3672 32.4912 42.3672 32.9989C42.3672 33.5067 42.7771 33.9166 43.2848 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M47.1022 33.9166C47.61 33.9166 48.0199 33.5067 48.0199 32.9989C48.0199 32.4912 47.61 32.0813 47.1022 32.0813C46.5945 32.0813 46.1846 32.4912 46.1846 32.9989C46.1846 33.5067 46.5945 33.9166 47.1022 33.9166Z"
        fill="#6A5CB9"
      />
      <defs>
        <clipPath id="clip0_10861_621">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.517578 0.900024)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="c-logo__mobile-icon c-logo__mobile-icon--burger"
      :class="{ 'c-logo__mobile-icon--visible': isBurgerVisible }"
    >
      <line
        x1="0.75"
        y1="1.25"
        x2="15.25"
        y2="1.25"
        stroke="#A1A5B0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <line
        x1="0.75"
        y1="7.6499"
        x2="15.25"
        y2="7.6499"
        stroke="#A1A5B0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <line
        x1="0.75"
        y1="14.05"
        x2="15.25"
        y2="14.05"
        stroke="#A1A5B0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  </button>
  <a
    v-else-if="type == 'drawer'"
    :href="href"
    class="c-logo c-logo--drawer"
    @click="handleClick"
  >
    <svg
      width="30"
      height="30"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10861_621)">
        <path
          d="M40.7376 32.9C40.7376 34.4 39.9376 35.71 38.7376 36.42L36.7676 32.9L38.7376 29.38C39.9376 30.1 40.7376 31.41 40.7376 32.9Z"
          fill="white"
        />
        <path
          d="M38.2374 33.77C38.8874 33.92 39.4474 33.36 39.2874 32.72C39.2174 32.42 38.9674 32.17 38.6674 32.1C38.0174 31.95 37.4574 32.51 37.6174 33.15C37.6874 33.45 37.9374 33.7 38.2374 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M41.9474 33.77C42.5974 33.92 43.1574 33.36 42.9974 32.72C42.9274 32.42 42.6774 32.17 42.3774 32.1C41.7274 31.95 41.1674 32.51 41.3274 33.15C41.3974 33.45 41.6474 33.7 41.9474 33.77Z"
          fill="#6A5CB9"
        />
        <path
          d="M45.548 33.77C46.198 33.92 46.758 33.36 46.598 32.72C46.528 32.42 46.278 32.17 45.978 32.1C45.328 31.95 44.768 32.51 44.928 33.15C44.998 33.45 45.248 33.7 45.548 33.77Z"
          fill="#6A5CB9"
        />
      </g>
      <path
        d="M44.8692 45.8601C42.6734 47.1187 40.1348 47.8334 37.4249 47.8334C29.1558 47.8334 22.4291 41.1347 22.4291 32.9001C22.4291 24.6654 29.1558 17.9667 37.4249 17.9667C40.1348 17.9667 42.6734 18.6814 44.8692 19.9507L50.1071 10.6387C46.3688 8.51605 42.0415 7.30005 37.4249 7.30005C23.2217 7.30005 11.7178 18.7667 11.7178 32.9001C11.7178 47.0334 23.2217 58.5001 37.4249 58.5001C42.0415 58.5001 46.3795 57.2947 50.1178 55.1721L44.8692 45.8601Z"
        fill="#8573E7"
      />
      <path
        d="M37.3107 37.284C34.8911 37.284 32.9299 35.3214 32.9299 32.9C32.9299 30.4787 34.8911 28.516 37.3107 28.516C38.1314 28.516 38.8988 28.74 39.5597 29.1454L42.1178 24.6014C40.7108 23.7694 39.0694 23.3 37.3107 23.3C32.0133 23.3 27.7178 27.5987 27.7178 32.9C27.7178 38.2014 32.0133 42.5 37.3107 42.5C39.0587 42.5 40.7002 42.0307 42.1071 41.1987L39.5597 36.6547C38.8988 37.0601 38.1314 37.284 37.3107 37.284Z"
        fill="#8573E7"
      />
      <path
        d="M53.3176 32.9941C53.3176 34.3461 52.7731 35.5696 51.886 36.4567C51.5496 36.7932 51.1642 37.0807 50.7359 37.3009C50.7298 37.3132 50.7237 37.3132 50.7115 37.3193C50.6992 37.3254 50.6809 37.3376 50.6625 37.3499C49.1943 38.1819 48.454 39.6929 48.454 41.4976V41.8524L46.7533 39.1729C46.2517 38.3837 45.5788 37.8882 44.6611 37.8882H37.4117C34.7077 37.8882 32.5176 35.6981 32.5176 32.9941C32.5176 31.6421 33.062 30.4186 33.9491 29.5315C34.8362 28.6506 36.0597 28.1 37.4117 28.1H48.4235C51.1275 28.1 53.3176 30.2901 53.3176 32.9941Z"
        fill="#D8D1F7"
      />
      <path
        d="M39.3522 33.9166C39.86 33.9166 40.2699 33.5067 40.2699 32.9989C40.2699 32.4912 39.86 32.0813 39.3522 32.0813C38.8445 32.0813 38.4346 32.4912 38.4346 32.9989C38.4346 33.5067 38.8445 33.9166 39.3522 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M43.2848 33.9166C43.7926 33.9166 44.2025 33.5067 44.2025 32.9989C44.2025 32.4912 43.7926 32.0813 43.2848 32.0813C42.7771 32.0813 42.3672 32.4912 42.3672 32.9989C42.3672 33.5067 42.7771 33.9166 43.2848 33.9166Z"
        fill="#6A5CB9"
      />
      <path
        d="M47.1022 33.9166C47.61 33.9166 48.0199 33.5067 48.0199 32.9989C48.0199 32.4912 47.61 32.0813 47.1022 32.0813C46.5945 32.0813 46.1846 32.4912 46.1846 32.9989C46.1846 33.5067 46.5945 33.9166 47.1022 33.9166Z"
        fill="#6A5CB9"
      />
      <defs>
        <clipPath id="clip0_10861_621">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0.517578 0.900024)"
          />
        </clipPath>
      </defs>
    </svg>
    <span class="c-logo__text"><slot /></span>
  </a>
</template>
<script>
export default {
  name: 'LogoComponent',
  props: {
    type: {
      type: String,
      default: 'mobile',
    },
    href: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHovered: false,
      timeoutId: null,
      isBurgerVisible: false,
    };
  },
  computed: {
    envNamespace() {
      return window?.contactCenterConfig?.designSystemEnvNamespace;
    },
  },
  watch: {
    $route() {
      this.isBurgerVisible = false;
      this.timeoutId = setTimeout(() => {
        this.isBurgerVisible = true;
      }, [3000]);
    },
  },
  mounted() {
    this.timeoutId = setTimeout(() => {
      this.isBurgerVisible = true;
    }, [3000]);
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    onMouseEnter() {
      this.isHovered = true;
    },
    onMouseLeave() {
      this.isHovered = false;
    },
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/woot';
.c-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  @include breakpoint(large) {
    display: none;
  }

  &--desktop {
    display: none;
    height: 60px;
    padding-left: 60px;
    @include breakpoint(large) {
      display: flex;
    }
  }

  &--login {
    height: 60px;
    padding-left: 60px;
    @include breakpoint(large) {
      display: flex;
    }
  }

  &--mobile {
    background-color: transparent;
    border: none;
  }

  &--drawer {
    gap: 10px;
  }

  &__text {
    font-size: $font-size-large;
    font-weight: 700;
    color: $color-grey-500;
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    left: 0;
    transition: 0.2s opacity ease;
    &--hovered {
      opacity: 0;
    }
    &--active {
      opacity: 1;
    }
  }

  &__mobile-icon {
    transition: 0.2s opacity ease;
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);

    &--burger {
      opacity: 0;
    }

    &--visible {
      opacity: 1;
    }
    &--invisible {
      opacity: 0;
    }
  }
}
</style>
