<template>
  <woot-modal
    :show="showModal"
    :show-close-button="false"
    :close-on-backdrop-click="false"
    :on-close="onClose"
  >
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header header-title="Session is expired" />
      <div class="p-8">
        <p>
          Your session has expired. Please Log In again to continue using the
          application.
        </p>
      </div>
      <div class="modal-footer delete-item">
        <button class="button large expanded nice" @click="logout">
          Log In
        </button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUS_EVENTS } from '../../shared/constants/busEvents';
import Auth from '../api/auth';

export default {
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  mounted() {
    window.bus.$on(BUS_EVENTS.SESSION_EXPIRED, () => {
      this.showModal = true;
    });
  },
  methods: {
    async logout() {
      this.showModal = false;
      if (this.$route.name === 'error' && !this.isLoggedIn) {
        await Auth.kratosLogout();
        window.location.replace('/');
      } else {
        Auth.logout();
      }
    },
    onClose() {},
  },
};
</script>
