var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 overflow-auto p-4"},[_c('div',{staticClass:"flex flex-row gap-4"},[_c('div',{staticClass:"w-[60%]"},[(!_vm.teamsList.length)?_c('p',{staticClass:"flex h-full items-center flex-col justify-center"},[_vm._v("\n        "+_vm._s(_vm.$t('TEAMS_SETTINGS.LIST.404'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.teamsList.length)?_c('div',{staticClass:"woot-table"},_vm._l((_vm.teamsList),function(item){return _c('div',{key:item.id,staticClass:"border-b border-pype-grey-200"},[_c('div',{staticClass:"flex justify-between items-center py-4"},[_c('div',[_c('span',{staticClass:"team-name"},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.description))])]),_vm._v(" "),_c('div',[(_vm.isAdmin)?_c('woot-button',{class:{ 'rotate-180': _vm.isActiveState(item.id) },attrs:{"variant":"smooth","size":"tiny","color-scheme":"secondary","icon":"chevron-down"},on:{"click":function($event){return _vm.onShowTeamMembersClick(item.id)}}}):_vm._e()],1)]),_vm._v(" "),(_vm.isActiveState(item.id))?_c('div',[(_vm.UIFlags.isFetching)?_c('div',[_c('span',{staticClass:"spinner mt-4 mb-4"})]):_c('div',{staticClass:"flex gap-2 align-middle"},[_c('table',{staticClass:"woot-table"},[_c('tbody',_vm._l((_vm.getTeamMembers(item.id)),function(agent,idx){return _c('tr',{key:agent.email,class:{
                      'border-b-0':
                        _vm.getTeamMembers(item.id).length - 1 === idx,
                    }},[_c('td',[_c('thumbnail',{staticClass:"columns ph-no-capture",attrs:{"src":agent.thumbnail,"username":agent.available_name,"size":"40px","status":agent.availability_status}})],1),_vm._v(" "),_c('td',[_c('span',{staticClass:"agent-name ph-no-capture"},[_vm._v("\n                        "+_vm._s(agent.available_name)+"\n                      ")]),_vm._v(" "),_c('span',{staticClass:"ph-no-capture"},[_vm._v(_vm._s(agent.email))])]),_vm._v(" "),_c('td',[_c('span',{staticClass:"agent-name ph-no-capture"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                            ("AGENT_MGMT.AGENT_TYPES." + (agent.role ? agent.role.toUpperCase() : 'AGENT'))
                          ))+"\n                      ")]),_vm._v(" "),(agent.confirmed)?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t('AGENT_MGMT.LIST.VERIFIED'))+"\n                      ")]):_vm._e(),_vm._v(" "),(!agent.confirmed)?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t('AGENT_MGMT.LIST.VERIFICATION_PENDING'))+"\n                      ")]):_vm._e()])])}),0)])])]):_vm._e()])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"w-[34%]"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.$t('TEAMS_SETTINGS.SIDEBAR_TXT', {
            installationName: _vm.globalConfig.installationName,
          })
        ),expression:"\n          $t('TEAMS_SETTINGS.SIDEBAR_TXT', {\n            installationName: globalConfig.installationName,\n          })\n        "}]})])]),_vm._v(" "),(_vm.showDeletePopup)?_c('woot-confirm-delete-modal',{attrs:{"show":_vm.showDeletePopup,"title":_vm.confirmDeleteTitle,"message":_vm.$t('TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE'),"confirm-text":_vm.deleteConfirmText,"reject-text":_vm.deleteRejectText,"confirm-value":_vm.selectedTeam.name,"confirm-place-holder-text":_vm.confirmPlaceHolderText},on:{"update:show":function($event){_vm.showDeletePopup=$event},"on-confirm":_vm.confirmDeletion,"on-close":_vm.closeDelete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }