export const FEATURE_FLAGS = {
  AGENT_BOTS: 'agent_bots',
  AGENT_MANAGEMENT: 'agent_management',
  AUTO_RESOLVE_CONVERSATIONS: 'auto_resolve_conversations',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  CANNED_RESPONSES: 'canned_responses',
  CRM: 'crm',
  CUSTOM_ATTRIBUTES: 'custom_attributes',
  INBOX_MANAGEMENT: 'inbox_management',
  INTEGRATIONS: 'integrations',
  LABELS: 'labels',
  MACROS: 'macros',
  HELP_CENTER: 'help_center',
  REPORTS: 'reports',
  TEAM_MANAGEMENT: 'team_management',
  VOICE_RECORDER: 'voice_recorder',
  AUDIT_LOGS: 'audit_logs',
};

export const LAUNCH_DARKLY_FLAGS = {
  AUDIT_LOGS: 'pe-27537-hide-audit-logs-tab-in-settings',
  PENDING_MARK_BUTTON: 'pe-27527-hide-pending-status-for-conversations',
  DIALOGFLOW_AND_DYTE:
    'pe-27528-hide-dialog-flow-and-dyte-from-applications-tab-in-settings',
  EMAIL_SUBJECT_AND_SEND_ATTACHMENT:
    'pe-27536-hide-email-subject-in-conditions-and-send-attachment-in-actions',
  NEW_MESSAGE_CONTACT_SECTION:
    'pe-27491-hide-new-message-in-all-contact-sections-behind-a-feature-flag',
  BOT_SETTINGS: 'pe-27757-hide-bot-configuration',
  CSAT_REPORTS: 'pe-27645-hide-csat-tab-in-reports-page',
  NUMBER_OF_DAYS_OF_INACTIVITY: 'pe-27981',
  HIDE_PYPE_INBOX: 'pe-28359-hide-pype-inbox',
  HIDE_WEBSITE_INBOX: 'pe-28359-hide-website-inbox',
  HIDE_MESSANGER_INBOX: 'pe-28539-hide-messenger-inbox',
  HIDE_WATSAPP_INBOX: 'pe-28539-hide-wats-app-inbox',
  HIDE_SMS_INBOX: 'pe-28539-hide-sms-inbox',
  HIDE_EMAIL_INBOX: 'pe-28539-hide-email-inbox',
  HIDE_API_INBOX: 'pe-28539-hide-api-inbox',
  HIDE_TELEGRAM_INBOX: 'pe-28539-hide-telegram-inbox',
  HIDE_LINE_INBOX: 'pe-28539-hide-line-inbox',
  HIDE_ADD_ATTACHMENT_SHORTCUT:
    'pe-28291-put-add-attachment-keyboard-shortcut-behind-a-feature-flag',
  HIDE_PENDING_STATUS: 'pe-29100-hide-pending-status',
  HIDE_SNOOZED_STATUS: 'pe-29100-hide-snoozed-status',
  SHOW_ATTACHMENT_WEB:
    'pe-29659-show-attachment-button-for-the-web-widget-inbox-type',
  SHOW_ATTACHMENT_FACEBOOK:
    'pe-29659-show-attachment-button-for-the-facebook-inbox-type',
  SHOW_ATTACHMENT_WATSAPP:
    'pe-29659-show-attachment-button-for-the-watsapp-inbox-type',
  SHOW_ATTACHMENT_API: 'pe-29659-show-attachment-button-for-the-api-inbox-type',
  SHOW_ATTACHMENT_EMAIL:
    'pe-29659-show-attachment-button-for-the-email-inbox-type',
  SHOW_ATTACHMENT_SMS: 'pe-29659-show-attachment-button-for-the-sms-inbox-type',
  SHOW_ATTACHMENT_TELEGRAM:
    'pe-29659-show-attachment-button-for-the-telegram-inbox-type',
  SHOW_ATTACHMENT_PYPE:
    'pe-29659-show-attachment-button-for-the-pype-inbox-type',
};
