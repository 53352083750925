import types from '../mutation-types';
import Vue from 'vue';

export const state = {
  featureFlags: {},
};

export const getters = {
  getFeatureFlags(_state) {
    return _state.featureFlags;
  },
};

export const actions = {
  async fetchFeatureFlags({ commit }) {
    const data = Vue.prototype.$launchDarklyClient.allFlags();
    commit(types.SET_LAUNCH_DARKLY_FLAGS, data);
  },
};

export const mutations = {
  [types.SET_LAUNCH_DARKLY_FLAGS](_state, data) {
    _state.featureFlags = {
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
