<template>
  <div class="c-app-bar-controls row gap-3">
    <ps-nav-extra>
      <notification-bell
        class="notification"
        @open-notification-panel="openNotificationPanel"
      />
    </ps-nav-extra>

    <ps-nav-actions class="c-app-bar-controls__actions">
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @change-availability-status="toggleOptions"
        @close="toggleOptions"
      />
    </ps-nav-actions>
  </div>
</template>
<script>
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  components: {
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';
.c-app-bar-controls {
  width: 100%;
  justify-content: flex-end;

  &__actions {
    display: none;
    @include breakpoint(large) {
      display: inline-flex;
    }
  }
}
.primary--sidebar {
  display: flex;
  flex-direction: column;
  width: var(--space-jumbo);
  border-right: 1px solid var(--s-50);
  box-sizing: content-box;
  height: 100%;
  flex-shrink: 0;
}

.notification {
  margin-bottom: 0;
}

.menu {
  align-items: center;
  margin-top: var(--space-medium);
}

.user-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: var(--space-normal);
}
</style>
