<template>
  <div class="date-picker">
    <date-picker
      :range="true"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :disabled-date="isDisabledFutureDates ? disabledAfterToday : undefined"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    isDisabledFutureDates: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const updateCalendars = DatePicker.CalendarRange.methods.updateCalendars;

    DatePicker.CalendarRange.methods.updateCalendars = function changeRangePickerViewPort(
      ...args
    ) {
      updateCalendars.apply(this, args);
      if (args.length === 1) {
        const isRangeDataProvided = this.innerValue.length === 2;
        if (isRangeDataProvided) {
          const isDataInSameMontes =
            this.innerValue[0]?.getMonth() === this.innerValue[1]?.getMonth();
          if (isDataInSameMontes) {
            this.calendars = this.calendars.map(v => {
              const next = new Date(v);
              next.setMonth(next.getMonth() - 1);
              return next;
            });
          }
        }
      }
    };
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
  },
};
</script>
