<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="row">
      <div class="column small-12 medium-8 conversation-metric">
        <metric-card
          :header="this.$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.HEADER')"
          :is-loading="uiFlags.isFetchingAccountConversationMetric"
          :loading-message="
            $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE')
          "
        >
          <div
            v-for="(metric, name, index) in conversationMetrics"
            :key="index"
            class="metric-content column"
          >
            <ps-text-subtitle size="xsmall" class="subtitle">
              {{ name }}
            </ps-text-subtitle>
            <ps-text class="metric">{{ metric }}</ps-text>
          </div>
        </metric-card>
      </div>
      <div class="column small-12 medium-4">
        <metric-card :header="this.$t('OVERVIEW_REPORTS.AGENT_STATUS.HEADER')">
          <div
            v-for="(metric, name, index) in agentStatusMetrics"
            :key="index"
            class="metric-content column"
          >
            <ps-text-subtitle size="xsmall" class="subtitle">
              {{ name }}
            </ps-text-subtitle>
            <ps-text class="metric">{{ metric }}</ps-text>
          </div>
        </metric-card>
      </div>
      <div class="column small-12">
        <metric-card
          :header="$t('OVERVIEW_REPORTS.STATS.HEADER')"
          :is-loading="uiFlags.isFetchingAccountConversationMetric"
          :loading-message="
            $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE')
          "
        >
          <div class="metric-content column">
            <ps-text-subtitle size="xsmall" class="subtitle">
              {{ $t('OVERVIEW_REPORTS.STATS.AVG_FRT') }}
            </ps-text-subtitle>
            <ps-text class="metric">
              {{ renderStatsLabel(accountSummary.avg_first_response_time) }}
            </ps-text>
          </div>
          <div class="metric-content column">
            <ps-text-subtitle size="xsmall" class="subtitle">
              {{ $t('OVERVIEW_REPORTS.STATS.AVG_RT') }}
            </ps-text-subtitle>
            <ps-text class="metric">
              {{ renderStatsLabel(accountSummary.avg_resolution_time) }}
            </ps-text>
          </div>
          <div class="metric-content column">
            <ps-text-subtitle size="xsmall" class="subtitle">
              {{ $t('OVERVIEW_REPORTS.STATS.MAX_FRT') }}
            </ps-text-subtitle>
            <ps-text class="metric">
              {{ renderStatsLabel(accountSummary.max_first_response_time) }}
            </ps-text>
          </div>
        </metric-card>
      </div>
    </div>
    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.HEADER')"
        is-break-title
      >
        <template #control>
          <woot-button
            icon="arrow-download"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            @click="downloadHeatmapData"
          >
            {{ $t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.BUTTON_LABEL') }}
          </woot-button>
        </template>
        <report-heatmap
          :heat-data="accountConversationHeatmap"
          :is-loading="uiFlags.isFetchingAccountConversationsHeatmap"
        />
      </metric-card>
    </div>
    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')"
      >
        <template #control>
          <div class="small-12 pull-right">
            <div class="row">
              <div class="column small-6 select">
                <multiselect
                  v-model="currentSelectedFilterType"
                  track-by="id"
                  open-direction="below"
                  label="name"
                  :options="filterType"
                  :searchable="false"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  deselect-label=""
                />
              </div>
              <div class="column small-6 select">
                <multiselect
                  v-model="currentSelectedFilter"
                  track-by="id"
                  open-direction="below"
                  label="name"
                  :options="filterItemsList"
                  :searchable="false"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  deselect-label=""
                />
              </div>
            </div>
          </div>
        </template>
        <agent-table
          :agents="filteredAgents"
          :agent-metrics="agentConversationMetric"
          :page-index="pageIndex"
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
          @page-change="onPageNumberChange"
        />
      </metric-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AgentTable from './components/overview/AgentTable';
import MetricCard from './components/overview/MetricCard';
import { GROUP_BY_FILTER, OVERVIEW_METRICS } from './constants';
import ReportHeatmap from './components/Heatmap';
import { formatTime } from '@chatwoot/utils';

import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

export default {
  name: 'LiveReports',
  components: {
    AgentTable,
    MetricCard,
    ReportHeatmap,
  },
  data() {
    return {
      pageIndex: 1,
      currentSelectedFilterType: {},
      currentSelectedFilter: {},
    };
  },
  computed: {
    ...mapGetters({
      agentStatus: 'agents/getAgentStatus',
      agents: 'agents/getAgents',
      accountConversationMetric: 'getAccountConversationMetric',
      agentConversationMetric: 'getAgentConversationMetric',
      accountConversationHeatmap: 'getAccountConversationHeatmapData',
      uiFlags: 'getOverviewUIFlags',
      teams: 'teams/getTeams',
      inboxes: 'inboxes/getInboxes',
      accountSummary: 'getAccountSummary',
    }),
    filterType() {
      return [
        {
          id: 0,
          name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.TEAM'),
        },
        {
          id: 1,
          name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.INBOX'),
        },
      ];
    },
    agentStatusMetrics() {
      let metric = {};
      const agentStatus = {
        online: this.filteredAgents.filter(
          agent => agent.availability_status === 'online'
        ).length,
        busy: this.filteredAgents.filter(
          agent => agent.availability_status === 'busy'
        ).length,
        offline: this.filteredAgents.filter(
          agent => agent.availability_status === 'offline'
        ).length,
      };
      Object.keys(agentStatus).forEach(key => {
        const metricName = this.$t(
          `OVERVIEW_REPORTS.AGENT_STATUS.${OVERVIEW_METRICS[key]}`
        );
        metric[metricName] = agentStatus[key];
      });
      return metric;
    },
    conversationMetrics() {
      let metric = {};
      Object.keys(this.accountConversationMetric).forEach(key => {
        const metricName = this.$t(
          `OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.${OVERVIEW_METRICS[key]}`
        );
        metric[metricName] = this.accountConversationMetric[key];
      });
      return metric;
    },
    filterItemsList() {
      if (this.currentSelectedFilterType.id === 0) {
        return [
          {
            id: 0,
            name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.ALL'),
          },
          ...this.teams,
        ];
      }
      const mapedInboxes = this.inboxes.map(({ name, id }) => ({ id, name }));
      return [
        {
          id: 0,
          name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.ALL'),
        },
        ...mapedInboxes,
      ];
    },
    filteredAgents() {
      if (this.currentSelectedFilter.id === 0) return this.agents;
      return this.agents.filter(({ team_ids, inbox_ids }) => {
        if (this.currentSelectedFilterType.id === 0) {
          return team_ids.includes(this.currentSelectedFilter.id);
        }
        return inbox_ids.includes(this.currentSelectedFilter.id);
      });
    },
  },
  watch: {
    currentSelectedFilterType() {
      this.currentSelectedFilter = {
        id: 0,
        name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.ALL'),
      };
    },
    '$i18n.locale'() {
      this.setDefaultFilters();
    },
  },
  async mounted() {
    await this.$store.dispatch('agents/get');
    await this.fetchAllData();
    this.setDefaultFilters();
    bus.$on('fetch_overview_reports', () => {
      this.fetchAllData();
    });
  },

  methods: {
    formatTime,
    setDefaultFilters() {
      this.currentSelectedFilterType = this.filterType[0];
      this.currentSelectedFilter = {
        id: 0,
        name: this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.FILTERS.ALL'),
      };
    },
    fetchAllData() {
      const date = new Date();
      this.fetchAccountConversationMetric();
      this.fetchAgentConversationMetric();
      this.fetchHeatmapData();
      this.$store.dispatch('fetchAccountSummary', {
        from: getUnixTime(startOfDay(date)),
        to: getUnixTime(endOfDay(date)),
        type: this.type,
        id: this.selectedFilter?.id,
        groupBy: GROUP_BY_FILTER[1],
        businessHours: false,
      });
      this.$store.dispatch('inboxes/get');
    },
    downloadHeatmapData() {
      let to = endOfDay(new Date());

      this.$store.dispatch('downloadAccountConversationHeatmap', {
        to: getUnixTime(to),
      });
    },
    fetchHeatmapData() {
      if (this.uiFlags.isFetchingAccountConversationsHeatmap) {
        return;
      }

      // the data for the last 6 days won't ever change,
      // so there's no need to fetch it again
      // but we can write some logic to check if the data is already there
      // if it is there, we can refetch data only for today all over again
      // and reconcile it with the rest of the data
      // this will reduce the load on the server doing number crunching
      let to = endOfDay(new Date());
      let from = startOfDay(subDays(to, 6));

      if (this.accountConversationHeatmap.length) {
        to = endOfDay(new Date());
        from = startOfDay(to);
      }

      this.$store.dispatch('fetchAccountConversationHeatmap', {
        metric: 'conversations_count',
        from: getUnixTime(from),
        to: getUnixTime(to),
        groupBy: 'hour',
        businessHours: false,
      });
    },
    fetchAccountConversationMetric() {
      this.$store.dispatch('fetchAccountConversationMetric', {
        type: 'account',
      });
    },
    fetchAgentConversationMetric() {
      this.$store.dispatch('fetchAgentConversationMetric', {
        type: 'agent',
        page: this.pageIndex,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.fetchAgentConversationMetric();
    },
    renderStatsLabel(value) {
      const label = this.formatTime(value);
      if (label === 'NaN Sec') {
        return '-';
      }
      return label;
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  margin-bottom: 8px;
  display: inline-block;
}
.multiselect {
  margin-bottom: 0;
}
.select {
  padding: 0 4px;
}
</style>
