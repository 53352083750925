<template>
  <div class="flex-grow flex-shrink min-w-0 p-6 overflow-auto">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div
        class="flex flex-row p-4 border-b border-slate-25 dark:border-slate-800"
      >
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              disabled
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @blur="$v.name.$touch"
            />
            <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.locale.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
            <select v-model="locale">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{
              $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
            }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
            <input
              v-model="domain"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
            />
          </label>
          <label v-if="featureCustomReplyEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input
              v-model="supportEmail"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
            />
          </label>
          <label>
            {{ $t('GENERAL_SETTINGS.FORM.PROMPT_WORD_LIMIT.LABEL') }}
            <input
              v-model="promptWordLimit"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.PROMPT_WORD_LIMIT.PLACEHOLDER')
              "
            />
          </label>
          <label
            v-if="showAutoResolutionConfig"
            :class="{ error: $v.autoResolveDuration.$error }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
            <input
              v-model="autoResolveDuration"
              type="number"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
              "
              @blur="$v.autoResolveDuration.$touch"
            />
            <span v-if="$v.autoResolveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
            </span>
          </label>
          <div class="flex items-center gap-2">
            <input
              v-model="mandatoryLabelCheck"
              type="checkbox"
              class="cursor-pointer m-0"
            />
            <label>
              {{ $t('GENERAL_SETTINGS.FORM.MANDATORY_CHECKS.LABEL_RESOLVE') }}
            </label>
          </div>
          <div class="flex items-center gap-2">
            <input
              v-model="mandatorySummaryOnTransfer"
              type="checkbox"
              class="cursor-pointer m-0"
            />
            <label>
              {{
                $t('GENERAL_SETTINGS.FORM.MANDATORY_CHECKS.SUMMARY_TRANSFER')
              }}
            </label>
          </div>
          <div class="flex items-center gap-2">
            <input
              v-model="mandatoryPrivateNoteCheck"
              type="checkbox"
              class="cursor-pointer m-0"
            />
            <label>
              {{ $t('GENERAL_SETTINGS.FORM.MANDATORY_CHECKS.SUMMARY_RESOLVE') }}
            </label>
          </div>
        </div>
      </div>

      <div
        class="p-4 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300 row"
      >
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <woot-code :script="getAccountId" />
        </div>
      </div>
      <!-- <div class="text-sm text-center p-4">
        <div>{{ `v${globalConfig.appVersion}` }}</div>
        <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
          {{
            $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
              latestChatwootVersion: latestChatwootVersion,
            })
          }}
        </div>
        <div class="build-id">
          <div>{{ `Build ${globalConfig.gitSha}` }}</div>
        </div>
      </div> -->

      <woot-submit-button
        class="button nice success button--fixed-top"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';
import { LAUNCH_DARKLY_FLAGS } from '../../../../featureFlags';

export default {
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      promptWordLimit: null,
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
      mandatoryLabelCheck: false,
      mandatoryPrivateNoteCheck: false,
      mandatorySummaryOnTransfer: false,
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      featureFlags: 'featureFlags/getFeatureFlags',
    }),
    showAutoResolutionConfig() {
      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
        ) &&
        !this.featureFlags[LAUNCH_DARKLY_FLAGS.NUMBER_OF_DAYS_OF_INACTIVITY]
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },
  methods: {
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
          mandatory_label_check,
          mandatory_private_note,
          mandatory_summary_on_transfer,
          prompt_word_limit,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.promptWordLimit = prompt_word_limit;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;
        this.mandatoryLabelCheck = !!this.getMandatoryFieldValue(
          mandatory_label_check
        );
        this.mandatoryPrivateNoteCheck = !!this.getMandatoryFieldValue(
          mandatory_private_note
        );
        this.mandatorySummaryOnTransfer = !!this.getMandatoryFieldValue(
          mandatory_summary_on_transfer
        );
      } catch (error) {
        // Ignore error
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }
      try {
        await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          prompt_word_limit: this.promptWordLimit,
          auto_resolve_duration: this.autoResolveDuration,
          mandatory_label_check: Number(this.mandatoryLabelCheck),
          mandatory_private_note: Number(this.mandatoryPrivateNoteCheck),
          mandatory_summary_on_transfer: Number(
            this.mandatorySummaryOnTransfer
          ),
        });
        this.$root.$i18n.locale = this.locale;

        await this.$store.dispatch('accounts/get');

        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);

        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },
    getMandatoryFieldValue(value) {
      switch (value) {
        case 'optional':
          return 0;
        case 'required':
          return 1;
        case 'not_offered':
          return 0;

        default:
          return 0;
      }
    },
    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },
  },
};
</script>
