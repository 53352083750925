<template>
  <div class="c-options-menu-mobile">
    <div class="c-options-menu-mobile__user-block">
      <div class="c-options-menu-mobile__user-block-col">
        <ps-avatar :src="currentUser.avatar_url" :label="currentUser.name" />
        <div class="c-options-menu-mobile__user-data">
          <span class="c-options-menu-mobile__user-name text-truncate">{{
            currentUser.name
          }}</span>
          <span class="c-options-menu-mobile__user-email text-truncate">{{
            currentUser.email
          }}</span>
        </div>
      </div>
      <router-link
        v-slot="{ href, isActive, navigate }"
        :to="`/app/accounts/${accountId}/profile/settings`"
        custom
      >
        <a
          :href="href"
          class="c-options-menu-mobile__link button small clear secondary"
          :class="{ 'is-active': isActive }"
          @click="e => handleProfileSettingClick(e, navigate)"
        >
          <fluent-icon icon="settings" size="14" class="icon icon--font" />
        </a>
      </router-link>
    </div>
    <woot-dropdown-divider />
    <availability-status />
    <woot-dropdown-menu>
      <woot-dropdown-item v-if="showChangeAccountOption">
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size="small"
          icon="arrow-swap"
          @click="$emit('toggle-accounts')"
        >
          {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
        </woot-button>
      </woot-dropdown-item>
      <woot-dropdown-item v-if="globalConfig.chatwootInboxToken">
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size="small"
          icon="chat-help"
          @click="$emit('show-support-chat-window')"
        >
          {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
        </woot-button>
      </woot-dropdown-item>
      <woot-dropdown-item class="show-for-medium">
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size="small"
          icon="keyboard"
          @click="handleKeyboardHelpClick"
        >
          {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
        </woot-button>
      </woot-dropdown-item>
      <woot-dropdown-item>
        <router-link
          v-slot="{ href, isActive, navigate }"
          :to="`/app/accounts/${accountId}/profile/settings`"
          custom
        >
          <a
            :href="href"
            class="c-options-menu-mobile__link button small clear secondary"
            :class="{ 'is-active': isActive }"
            @click="e => handleProfileSettingClick(e, navigate)"
          >
            <fluent-icon icon="person" size="14" class="icon icon--font" />
            <span class="button__content">
              {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
            </span>
          </a>
        </router-link>
      </woot-dropdown-item>
      <woot-dropdown-item v-if="currentUser.type === 'SuperAdmin'">
        <a
          href="/super_admin"
          class="c-options-menu-mobile__link button small clear secondary"
          target="_blank"
          rel="noopener nofollow noreferrer"
          @click="$emit('close')"
        >
          <fluent-icon
            icon="content-settings"
            size="14"
            class="icon icon--font"
          />
          <span class="button__content">
            {{ $t('SIDEBAR_ITEMS.SUPER_ADMIN_CONSOLE') }}
          </span>
        </a>
      </woot-dropdown-item>
      <woot-dropdown-item>
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size="small"
          icon="power"
          @click="logout"
        >
          {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
        </woot-button>
      </woot-dropdown-item>
    </woot-dropdown-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
    WootDropdownDivider,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';
.c-options-menu-mobile {
  &__link.button.small {
    padding-left: 10px;
    padding-right: 10px;
  }
  &__user-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  &__user-block-col {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  &__user-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // for truncate email and user name
    width: 100%;
    max-width: 170px;
  }
  &__user-name {
    font-size: $font-size-small;
  }
  &__user-email {
    font-size: $font-size-small;
    color: $color-grey-300;
  }
}
</style>
