import { throwErrorMessage } from 'dashboard/store/utils/api';
import ConversationApi from '../../../../api/inbox/conversation';
import mutationTypes from '../../../mutation-types';
import types from '../../../mutation-types';

export default {
  markMessagesRead: async ({ commit }, data) => {
    try {
      const {
        data: {
          id,
          agent_last_seen_at: lastSeen,
          pending_required_fields: pendingRequiredFields,
        },
      } = await ConversationApi.markMessageRead(data);
      commit(types.UPDATE_ACTIONS_FIELD, {
        conversationId: id,
        pendingRequiredFields,
      });
      setTimeout(
        () =>
          commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, { id, lastSeen }),
        4000
      );
    } catch (error) {
      // Handle error
    }
  },

  markMessagesUnread: async ({ commit }, { id }) => {
    try {
      const {
        data: { agent_last_seen_at: lastSeen, unread_count: unreadCount },
      } = await ConversationApi.markMessagesUnread({ id });
      commit(mutationTypes.UPDATE_MESSAGE_UNREAD_COUNT, {
        id,
        lastSeen,
        unreadCount,
      });
    } catch (error) {
      throwErrorMessage(error);
    }
  },
};
