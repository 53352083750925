<template>
  <div class="h-screen">
    <app-bar
      @open-notification-panel="openNotificationPanel"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
    />
    <div
      class="relative h-[calc(100vh-82px)] flex-grow-0 min-h-0 w-full max-w-full ml-auto mr-auto flex flex-wrap dark:text-slate-300"
    >
      <sidebar
        :route="currentRoute"
        :show-secondary-sidebar="isSidebarOpen"
        @open-notification-panel="openNotificationPanel"
        @toggle-account-modal="toggleAccountModal"
        @open-key-shortcut-modal="toggleKeyShortcutModal"
        @close-key-shortcut-modal="closeKeyShortcutModal"
        @show-add-label-popup="showAddLabelPopup"
      />
      <section class="flex h-full min-h-0 overflow-hidden flex-1 px-0 z-[1]">
        <account-selector
          :show-account-modal="showAccountModal"
          @close-account-modal="toggleAccountModal"
          @show-create-account-modal="openCreateAccountModal"
        />
        <section class="app-content columns">
          <router-view />
          <command-bar />
          <account-selector
            :show-account-modal="showAccountModal"
            @close-account-modal="toggleAccountModal"
            @show-create-account-modal="openCreateAccountModal"
          />
          <!-- <add-account-modal
            :show="showCreateAccountModal"
            @close-account-create-modal="closeCreateAccountModal"
          /> -->
          <woot-key-shortcut-modal
            :show.sync="showShortcutModal"
            @close="closeKeyShortcutModal"
            @clickaway="closeKeyShortcutModal"
          />
          <notification-panel
            v-if="isNotificationPanel"
            @close="closeNotificationPanel"
          />
          <woot-modal
            :show.sync="showAddLabelModal"
            :on-close="hideAddLabelPopup"
          >
            <add-label-modal @close="hideAddLabelPopup" />
          </woot-modal>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar';
import AppBar from '../../components/layout/AppBar';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal';
// import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import wootConstants from 'dashboard/constants/globals';

export default {
  components: {
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    // AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
    AppBar,
  },
  mixins: [uiSettingsMixin],
  data() {
    return {
      showAccountModal: false,
      // showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      displayLayoutType: '',
    };
  },
  computed: {
    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const {
        previously_used_sidebar_view: showSecondarySidebar,
      } = this.uiSettings;
      return showSecondarySidebar;
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },

  methods: {
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },
    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: !this.isSidebarOpen,
        previously_used_sidebar_view: !this.isSidebarOpen,
      });
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      // this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
  },
};
</script>
