<template>
  <woot-dropdown-menu class="c-availability-status">
    <woot-dropdown-header :title="$t('SIDEBAR.SET_AVAILABILITY_TITLE')" />
    <woot-dropdown-item
      v-for="status in availabilityStatuses"
      :key="status.value"
      class="flex items-baseline"
    >
      <button
        class="c-availability-status__button"
        :class="{ 'c-availability-status__button--active': status.disabled }"
        @click="changeAvailabilityStatus(status.value)"
        @mouseenter="() => status.disabled && onItemMouseOver()"
        @mouseleave="() => status.disabled && onItemMouseLeave()"
      >
        <span>
          <availability-status-badge :status="status.value" />
          {{ status.label }}
        </span>

        <checkmark-icon
          v-if="status.disabled"
          :is-hovered="isHovered"
          class="c-availability-status__check-icon"
        />
      </button>
    </woot-dropdown-item>
    <!-- <woot-dropdown-divider /> -->
    <!-- <woot-dropdown-item class="m-0 flex items-center justify-between p-2">
      <div class="flex items-center">
        <fluent-icon
          v-tooltip.right-start="$t('SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT')"
          icon="info"
          size="14"
          class="mt-px"
        />

        <span
          class="my-0 mx-1 text-xs font-medium text-slate-600 dark:text-slate-100"
        >
          {{ $t('SIDEBAR.SET_AUTO_OFFLINE.TEXT') }}
        </span>
      </div>

      <woot-switch
        size="small"
        class="mt-px mx-1 mb-0"
        :value="currentUserAutoOffline"
        @input="updateAutoOffline"
      />
    </woot-dropdown-item> -->
    <woot-dropdown-divider />
  </woot-dropdown-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import WootDropdownHeader from 'shared/components/ui/dropdown/DropdownHeader';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';
import wootConstants from 'dashboard/constants/globals';
import CheckmarkIcon from 'shared/components/FluentIcon/Checkmark';
import AvailabilityStatusBadge from '../widgets/conversation/AvailabilityStatusBadge';
import {
  PYPESTREAM_ANALYTICS_EVENTS,
  captureCustomAnalyticsPypestreamEvent,
} from '../../helper/AnalyticsPypestreamHelper';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootDropdownHeader,
    WootDropdownDivider,
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatusBadge,
    CheckmarkIcon,
  },

  mixins: [clickaway, alertMixin],

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
      isHovered: false,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentUserAvailability: 'getCurrentUserAvailability',
      currentAccountId: 'getCurrentAccountId',
      currentUserAutoOffline: 'getCurrentUserAutoOffline',
    }),
    availabilityDisplayLabel() {
      const availabilityIndex = AVAILABILITY_STATUS_KEYS.findIndex(
        key => key === this.currentUserAvailability
      );
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[
        availabilityIndex
      ];
    },
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
  },

  methods: {
    openStatusMenu() {
      this.isStatusMenuOpened = true;
    },
    closeStatusMenu() {
      this.isStatusMenuOpened = false;
    },
    // updateAutoOffline(autoOffline) {
    //   this.$store.dispatch('updateAutoOffline', {
    //     accountId: this.currentAccountId,
    //     autoOffline,
    //   });
    // },
    changeAvailabilityStatus(availability) {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      try {
        this.$store
          .dispatch('updateAvailability', {
            availability,
            account_id: this.currentAccountId,
          })
          .then(() => {
            this.onStatusChangeAnalyticsEvent(availability);
          });

        this.$emit('change-availability-status');
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.SET_AVAILABILITY_ERROR')
        );
      } finally {
        this.isUpdating = false;
      }
    },
    onItemMouseOver() {
      this.isHovered = true;
    },
    onItemMouseLeave() {
      this.isHovered = false;
    },
    onStatusChangeAnalyticsEvent(status) {
      switch (status) {
        case 'online':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_ONLINE,
            isConversationEvent: false,
          });
          break;
        case 'busy':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_BUSY,
            isConversationEvent: false,
          });
          break;
        case 'offline':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_OFFLINE,
            isConversationEvent: false,
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables';

.c-availability-status {
  &__button {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-smaller);
    color: var(--gray-500);
    font-size: var(--font-size-small);
    background-color: transparent;
    border: none;
    height: 40px;
    padding: var(--space-smaller) var(--space-small);
    border-radius: var(--border-radius-normal);
    transition: background-color 0.2s ease;
    position: relative;

    &:hover {
      background-color: var(--hover-light-25);
    }

    &--active {
      font-weight: var(--font-weight-medium);
    }
  }

  &__check-icon {
    color: var(--pype-purple-500);
  }
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-micro) var(--space-smaller);
}

.status-view {
  display: flex;
  align-items: baseline;

  & &--title {
    color: var(--b-600);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-left: var(--space-small);

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.status-change {
  .dropdown-pane {
    top: -132px;
    right: var(--space-normal);
  }

  .status-items {
    display: flex;
    align-items: baseline;
  }
}

.auto-offline--toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-smaller);
  margin: 0;

  .info-wrap {
    display: flex;
    align-items: center;
  }

  .info-icon {
    margin-top: -1px;
  }

  .auto-offline--switch {
    margin: -1px var(--space-micro) 0;
  }

  .auto-offline--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-small);
    font-weight: 400;
    color: black;
  }
}
</style>
